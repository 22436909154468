import React, { useState } from 'react';
// import * as Yup from 'yup';
import { Link } from 'gatsby';
// import Button from 'components/Button';
// import TextInput from 'components/form-elements/TextInput';
// import { Formik, Form } from 'formik';
import Menus from './Menus';
import SocialMenus from './SocialMenus';
import AppStoreLinks from './AppStoreLinks';
import ContactInfo from './ContactInfo';
import { useStaticQueryGetNavItems } from 'shared/queries/navigations';
import { useAuth0 } from '@auth0/auth0-react';
import './footer.scss';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import { SLUGS } from 'utils/webPageTypesConstants';
import { amplitudeEvents, trackEvent } from 'utils/amplitude';
import Button from 'components/Button';
import TextInput from 'components/form-elements/TextInput';
import { Form, Formik } from 'formik';
import {
  // AUCTION_INITIAL_VALUES,
  FOOTER_INITIAL_VALUES,
  FOOTER_VALIDATION_SCHEMA,
} from 'components/utils/firstToKnowForm';
import styles from './footer.scss';
import {
  SEND_SUBSCRIPTION_EMAIL,
  // SEND_TERMS_AND_CONDITION_EMAIL,
} from 'services/graphql/queries/vehicles-catalogue';
import { useMutation } from '@apollo/client';
import Spinner from 'components/Spinner';
// import Alert from 'components/Alert';
import Recaptcha from 'react-recaptcha';
import MailSubmittedModal from 'components/mailSubmittedModal';
import Modal from 'components/Modal';

// const signUpInitialValues = { email: '' };
// const SignUpSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Please enter a valid email address.')
//     .required('Required'),
// });

const AppFooter = ({ link }) => {
  const { isAuthenticated, user } = useAuth0();
  const { footerNavItems } = useStaticQueryGetNavItems();
  const [isApproved, setIsApproved] = useState(false);
  const slugs = useWebPagesSlugs();
  const [
    sendEmail,
    { error: emailError, loading: isSendingEmail },
  ] = useMutation(SEND_SUBSCRIPTION_EMAIL);
  const renderNavigationItems = footerNavItems => {
    return (
      footerNavItems?.length &&
      footerNavItems.map(({ title, subElements, id, viewMode, slug }) => {
        const showMenu =
          (isAuthenticated && viewMode.includes('Logged In')) ||
          viewMode.includes('Visitor');

        if (showMenu) {
          return renderItemsAfterCheck(subElements, id, title, slug);
        } else {
          return null;
        }
      })
    );
  };

  const renderItemsAfterCheck = (subElements, id, title, slug) => {
    return subElements ? (
      <div key={id}>
        <div className="heading letterSpace">{title}</div>
        <ul>
          <Menus key={id} list={subElements} />
        </ul>
      </div>
    ) : (
      <div key={id}>
        <a href={slug ? slug : '/'}>
          <div className="heading letterSpace">{title}</div>
        </a>
      </div>
    );
  };
  const capitalizeName = name => {
    return name.replace(/\b(\w)/g, s => s.toUpperCase());
  };
  const [recaptchaInstance, setRecaptchaInstance] = useState();
  const submitForm = async values => {
    const fields = {
      title: `Join Mailing List - ${capitalizeName(values?.name)}`,
      name: capitalizeName(values?.name),
      email: values?.email,
    };
    sendEmail({
      variables: {
        fields,
      },
    }).then(res => {
      recaptchaInstance.reset();
      setRobot(true);
      setIsApproved(true);
    });
  };
  const [robot, setRobot] = useState(true);
  const callback = () => {};
  const verifyCallback = response => {
    if (response) {
      setRobot(false);
    }
  };
  const expiredCallback = () => {
    setRobot(true);
  };

  return (
    <div className="footer">
      {isSendingEmail && <Spinner />}
      {/* {emailError && <Alert color="danger" msg={emailError} />} */}
      <div className="mainWrapper">
        {/* <div className="footer-signUp">
          <h3>SIGN UP FOR UPDATES</h3>
          <Formik
            initialValues={signUpInitialValues}
            validationSchema={SignUpSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <TextInput
                  name="email"
                  placeholder="Enter Email Address"
                  floating={false}
                />
                <Button
                  type="app-transparent-white-button"
                  disabled={!!errors.email || !touched.email}
                  handleClick={() => {
                    if (link) {
                      window.location.href = link;
                    }
                  }}
                >
                  Sign Up
                </Button>
              </Form>
            )}
          </Formik>
        </div> */}

        {/* Fake div for keep the current design till signup back */}
        <div className="signupFakeWidth">
          <div>
            <Formik
              initialValues={FOOTER_INITIAL_VALUES}
              validationSchema={FOOTER_VALIDATION_SCHEMA}
              validateOnMount
              onSubmit={(values, { resetForm }) => {
                submitForm(values).then(res => {
                  resetForm();
                });
              }}
            >
              {({
                touched,
                isValid,
                isSubmitting,
                resetForm,
                setTouched,
                setFieldTouched,
              }) => (
                <Form id="contact-us-modal">
                  <div className={styles.signUpForm}>
                    <h3>Sign up for updates</h3>
                    <div>
                      <div>
                        <div className="footer-input">
                          <TextInput
                            name="name"
                            floating={false}
                            placeholder="Enter Your Name*"
                            showErrorMessages={false}
                          />
                        </div>

                        <div>
                          <TextInput
                            name="email"
                            floating={false}
                            placeholder="Enter Email Address*"
                            showErrorMessages={false}
                          />
                        </div>
                        <Recaptcha
                          ref={e => setRecaptchaInstance(e)}
                          css={{ width: '260px' }}
                          sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA}
                          render="explicit"
                          verifyCallback={verifyCallback}
                          expiredCallback={expiredCallback}
                          onloadCallback={callback}
                        />
                      </div>
                      <Button
                        btnType="submit"
                        type="app-transparent-white-button"
                        disabled={
                          !!!Object.keys(touched).length ||
                          !isValid ||
                          isSubmitting ||
                          robot
                        }
                      >
                        Sign Up
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <div className="footer-li flex-wrap">
          {renderNavigationItems(footerNavItems)}
        </div>

        <div className="address">
          <ContactInfo />
          <SocialMenus />
          <AppStoreLinks />
        </div>
      </div>

      <div className="terms">
        <p>
          <Link
            onClick={() => {
              trackEvent(amplitudeEvents.VIEW_TERMS_CONDITIONS, {
                email_address: user?.email,
              });
            }}
            to={`/${slugs[SLUGS.termsAndConditions]}`}
          >
            TERMS & CONDITIONS
          </Link>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <Link to={`/${slugs[SLUGS.privacyPolicy]}`}>PRIVACY POLICY</Link>
        </p>
        <p>
          ©{new Date().getFullYear()} by Gooding & Company Inc. All Rights
          Reserved.
        </p>
      </div>
      <Modal
        bodyText="Thank you for joining the Gooding & Company mainling list."
        title="Submitted"
        isOpen={isApproved}
      />
    </div>
  );
};

export default AppFooter;
